import clsx from 'clsx';

import styles from './Checkbox.module.scss';

interface Props {
  name: string;
  onChange: (newValue: boolean) => void;
  checked?: boolean;
}

const Checkbox = ({ name, checked, onChange }: Props) => {
  const id = `input-${name}`;

  return (
    <input
      checked={checked}
      onChange={(e) => onChange(e.currentTarget.checked)}
      data-test-id={`checkbox-${name.replace(/\s/g, '-')}`}
      type="checkbox"
      id={id}
      name={name}
      className={clsx(
        'form-check-input mt-0',
        !checked && 'border border-light-400',
        styles['checkbox'],
      )}
    />
  );
};

export default Checkbox;
