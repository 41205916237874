import clsx from 'clsx';
import { PropsWithChildren } from 'react';

import styles from './Thumbnail.module.scss';

const Thumbnail = ({ children }: PropsWithChildren<{}>) => (
  <div className={clsx('bg-gradient-blue flex-shrink-0', styles['thumbnail'])}>
    <div className="position-relative h-100 w-100">{children}</div>
  </div>
);

export default Thumbnail;
