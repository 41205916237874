export const getBase64 = (file: string): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(new Blob([file]));
    reader.onloadend = () => {
      const response = reader.result as string;
      resolve(response);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });

export const getBase64FromFile = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const response = reader.result as string;
      resolve(response);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });

export const getFileNameFromUrl = (fileUrl: string) =>
  fileUrl.substring(fileUrl.lastIndexOf('/') + 1);

export const fileListNames = (inputFiles: FileList | null) => {
  const files = [];
  for (let i = 0; inputFiles && i < inputFiles.length; ++i) {
    const item = inputFiles.item(i);
    item && inputFiles.item(i) && files.push(item.name);
  }
  return files;
};

export const splitFileName = (fileName: string | undefined) => {
  if (fileName === undefined) return [];

  const fileArray = fileName.split('.');
  return fileArray;
};

export const readFile = (
  file: File | null,
  onLoadStart: () => void,
  onLoadEnd: (objectUrl: string | undefined | null) => void,
) => {
  const fileReader = new FileReader();

  fileReader.onloadstart = () => {
    onLoadStart();
  };

  fileReader.onloadend = () => {
    const _URL = window.URL || window.webkitURL;
    const objectUrl = file && _URL.createObjectURL(file);
    onLoadEnd(objectUrl);
  };

  file && fileReader.readAsDataURL(file);
};
