import clsx from 'clsx';
import { get, useFormContext } from 'react-hook-form';

interface Props {
  name: string;
  label: string;
  value: string;
  variant: 'main' | 'checklist';
}

const RadioInput = ({ name, label, value, variant = 'main' }: Props) => {
  const { register, formState } = useFormContext();
  const id = `input-${name}`;
  const error = get(formState.errors, name);

  return (
    <div className="form-check d-flex align-items-center">
      <input
        data-test-id={`radio-input-${name.replace(/\s/g, '-')}`}
        className={clsx(
          'form-check-input mt-0 d-flex align-items-center justify-content-center',
          error && 'is-invalid',
        )}
        type="radio"
        {...register(name)}
        id={id}
        value={value}
      />
      <label
        className={clsx('mb-0 ms-2', variant === 'checklist' && 'fw-normal text-cyan-blue')}
        htmlFor={id}
      >
        {label}
      </label>
      <p className="text-danger ms-1 my-2">
        <small>{error ? error.message : '\u00A0'}</small>
      </p>
    </div>
  );
};
export default RadioInput;
