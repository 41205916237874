import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  name: string;
  label: string;
}

const SwitchInput = ({ name, label }: Props) => {
  const { register } = useFormContext();
  const [isSelected, setSelected] = useState(false);

  const id = `input-${name}`;

  return (
    <div className="form-check form-switch d-flex align-items-center">
      <input
        data-test-id={`switch-input-${name.replace(/\s/g, '-')}`}
        type="checkbox"
        className="form-check-input"
        {...register(name)}
        onClick={() => setSelected(!isSelected)}
        id={id}
      />
      <label className="mb-0 ms-2 fw-normal" htmlFor={id}>
        {label || '\u00A0'}
      </label>
    </div>
  );
};
export default SwitchInput;
