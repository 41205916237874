import clsx from 'clsx';
import { get, useFormContext } from 'react-hook-form';

import styles from './CheckboxInput.module.scss';

interface Props {
  label?: string;
  name: string;
  isDropdown?: boolean;
}

const CheckboxInput = ({ name, label, isDropdown = false }: Props) => {
  const { register, formState } = useFormContext();
  const id = `input-${name}`;
  const error = get(formState.errors, name);

  return (
    <div>
      <div className="form-check d-flex align-items-center">
        <input
          data-test-id={`checkbox-${name.replace(/\s/g, '-')}`}
          type="checkbox"
          {...register(name)}
          id={id}
          className={clsx('me-2 form-check-input mt-0', styles['checkbox'], error && 'is-invalid')}
        />
        <label className="mb-0" htmlFor={id}>
          <span className={clsx('fw-normal m-0', isDropdown && 'text-gray-600')}>{label}</span>
        </label>
        {error && (
          <p className="text-danger ms-1 my-2">
            <small>{error ? error.message : '\u00A0'}</small>
          </p>
        )}
      </div>
    </div>
  );
};

export default CheckboxInput;
