import { ReactNode } from 'react';
import { useDrop } from 'react-dnd';

interface Props<T> {
  accept: string;
  onDrop: (item: T) => void;
  children: (canDrop: boolean, isOver: boolean) => ReactNode;
}

const DragTarget = <T,>({ children, accept, onDrop }: Props<T>) => {
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept,
      drop: onDrop,
      collect: (monitor) => ({ isOver: monitor.isOver(), canDrop: monitor.canDrop() }),
    }),
    [accept, onDrop],
  );

  return (
    <div ref={drop} className="w-100 h-100">
      {children(canDrop, isOver)}
    </div>
  );
};
export default DragTarget;
