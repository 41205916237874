import Image from 'next/image';

interface Props {
  name: string;
  src: string;
  onClick: () => void;
}

const TableIconInput = ({ name, onClick, src }: Props) => (
  <div>
    <div
      role="button"
      data-test-id={`add-button-${name}`}
      className="text-center pe-auto"
      onClick={onClick}
    >
      <div className="d-flex align-items-center justify-content-center">
        <Image src={src} width={24} height={24} alt="plus" />
      </div>
    </div>
  </div>
);
export default TableIconInput;
