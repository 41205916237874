import { PropsWithChildren } from 'react';
import { useDrag } from 'react-dnd';

interface Props<T> {
  item: T;
  type: string;
}

const Draggable = <T,>({ item, type, children }: PropsWithChildren<Props<T>>) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type,
    item,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1.0 }} className="h-100">
      {children}
    </div>
  );
};
export default Draggable;
