import clsx from 'clsx';
import Link from 'next/link';

interface Props {
  routesList: string[];
  routesDisplays: string[];
}

const NavBarBreadcrumbs = ({ routesList, routesDisplays }: Props) => {
  const buildRoute = (route: string, index: number) => routesList.slice(0, index + 1).join('/');

  const checkRoutes = (route: string, alternativeRoute: string) => {
    if (isNaN(parseInt(route))) {
      return route;
    }
    return alternativeRoute;
  };

  return (
    <div>
      <ol className="breadcrumb bg-transparent rounded-0 mb-0 p-0 d-flex align-items-center">
        {routesList.slice(0, -1).map((route, index, list) => (
          <Link href={`/${buildRoute(route, index)}`} key={route}>
            <a
              data-test-id={`breadcrumb-link-${index}`}
              className={clsx('fs-6 breadcrumb-item text-capitalize', {
                'text-gray-500': index !== list.length - 1,
                active: index === list.length - 1,
              })}
            >
              {routesDisplays[index].replaceAll('_', ' ')}
            </a>
          </Link>
        ))}

        <h5 className="fs-6 breadcrumb-item text-capitalize mb-0 active fw-bolder">
          {checkRoutes(routesList.slice(-1)[0], routesDisplays.slice(-1)[0]).replaceAll('_', ' ')}
        </h5>
      </ol>
    </div>
  );
};
export default NavBarBreadcrumbs;
