import clsx from 'clsx';
import { ChangeEvent } from 'react';
import { get, useFormContext } from 'react-hook-form';

interface Props {
  type: 'text' | 'password' | 'number' | 'email';
  autoComplete?: string;
  label?: string;
  name: string;
  required?: boolean;
  isDebounce?: boolean;
  placeholder?: string;
  variant?: 'main' | 'auth' | 'compact';
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void | undefined;
  value?: string;
  onClick?: () => void;
}

const TextInput = ({
  name,
  type,
  label,
  required,
  isDebounce,
  placeholder,
  variant = 'main',
  disabled = false,
  value,
  autoComplete,
  onChange,
}: Props) => {
  const { register, formState } = useFormContext();

  const id = `input-${name}`;
  const formError = get(formState.errors, name);
  let registerName;

  if (!isDebounce) {
    registerName = register(name);
  } else {
    registerName = register(name, {
      onChange: onChange,
    });
  }

  return (
    <div>
      {label && <label htmlFor={id}>{label}</label>}
      <input
        value={value}
        data-test-id={`${type}-input-${name.replace(/\s/g, '-')}`}
        placeholder={placeholder}
        required={required}
        type={type}
        {...registerName}
        id={id}
        disabled={disabled}
        autoComplete={autoComplete}
        className={clsx('form-control', formError && 'is-invalid', {
          'py-3 fs-6 mt-3': variant === 'auth',
        })}
      />
      {((variant !== 'auth' && variant !== 'compact') || formError) && (
        <p className={clsx('text-danger ms-3 mt-1', variant === 'compact' ? 'mb-1' : 'mb-3')}>
          {formError && <small>{formError.message}</small>}
        </p>
      )}
    </div>
  );
};
export default TextInput;
