import clsx from 'clsx';
import { useCallback, useEffect, useState } from 'react';

interface Props {
  eventCode: string;
  enable: boolean;
}

const CopyCodeCard = ({ eventCode, enable }: Props) => {
  const [copied, setCopied] = useState(false);

  const [copiedTimeout, setCopiedTimeout] = useState<NodeJS.Timeout>();

  const copyText = useCallback(() => {
    if (enable) {
      navigator.clipboard.writeText(eventCode);
      setCopied(true);

      if (copiedTimeout) {
        clearTimeout(copiedTimeout);
      }

      setCopiedTimeout(
        setTimeout(() => {
          setCopied(false);
        }, 2000),
      );
    }
  }, [enable, eventCode, copiedTimeout]);

  useEffect(
    () => () => {
      if (copiedTimeout) {
        clearTimeout(copiedTimeout);
      }
    },
    [copiedTimeout],
  );

  return (
    <div>
      <div className="row ms-0">
        <div
          className={clsx(
            'rounded-3 col d-flex flex-row justify-content-between row p-0 m-0',
            enable && 'bg-primary',
            !enable && 'bg-light',
          )}
        >
          <h5 className={clsx('col m-auto text-center', 'text-truncate fw-bolder text-white')}>
            {enable && eventCode}
          </h5>
          {!copied ? (
            <button
              data-test-id="copy-button"
              className={clsx(
                'col rounded-3 m-1 p-2 border-0',
                enable && 'fw-normal bg-white',
                !enable && 'bg-white',
              )}
            >
              <h6
                className={clsx(
                  'my-auto ms-auto text-center fw-bolder',
                  enable && 'text-primary',
                  !enable && 'text-light',
                )}
                data-test-id="copy-event-code-button"
                onClick={copyText}
              >
                Copy
              </h6>
            </button>
          ) : (
            <div
              className={clsx(
                'col rounded-3 m-1 p-2 border-0',
                enable && 'fw-normal bg-white',
                !enable && 'bg-white',
              )}
            >
              <h6
                className={clsx(
                  'my-auto ms-auto text-center fw-bolder',
                  enable && 'text-primary',
                  !enable && 'text-light',
                )}
                data-test-id="copy-event-code-button"
                onClick={copyText}
              >
                Copied!
              </h6>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default CopyCodeCard;
