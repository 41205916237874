import { useAuth } from '@hooks/useAuth';
import { useAbility } from 'hooks/useAbility';
import Router, { useRouter } from 'next/router';
import { PropsWithChildren, useEffect } from 'react';

const PrivateRoute = ({ children }: PropsWithChildren<{}>) => {
  const { asPath } = useRouter();
  const canUseRoute = useAbility(asPath);
  const { loggedIn, loading } = useAuth();

  useEffect(() => {
    if (loading) return;

    if (!loggedIn) {
      Router.push('/sign_in');
    }

    if (!canUseRoute) {
      Router.push('/');
    }
  }, [canUseRoute, loading, loggedIn]);

  if (loading || !loggedIn || !canUseRoute) {
    return null;
  }

  return <>{children}</>;
};

export default PrivateRoute;
