import clsx from 'clsx';
import Image from 'next/image';

import styles from './CustomizerButton.module.scss';

interface Props {
  icon: string;
  label: string;
  isToggle: boolean;
  open: boolean;
  onClick: () => void;
}

const CustomizerButton = ({ icon, label, open, isToggle, onClick }: Props) => (
  <div
    role="button"
    data-test-id={`customizer-menu-button-${label.replace(/\s/g, '-')}`}
    className={clsx('d-flex flex-column justify-content-center align-items-center', styles.tab, {
      'opened bg-purple rounded-bottom-big shadow-customizer py-2': !isToggle && open,
      toggle: isToggle,
    })}
    onClick={onClick}
  >
    <Image src={icon} alt="customizer" height={53} width={53} />
    {(!open || isToggle) && <label className="fs-5 text-white text-capitalize">{label}</label>}
    {open && !isToggle && (
      <div className="mt-2">
        <Image src="/assets/img/icons/pink-close-icon.svg" alt="close" height={13} width={13} />
      </div>
    )}
  </div>
);
export default CustomizerButton;
