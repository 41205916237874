import clsx from 'clsx';
import { ReactNode } from 'react';

import styles from './Table.module.scss';

interface Props {
  titles?: ReactNode[];
  children: ReactNode;
  center?: boolean;
  fixedLayout?: boolean;
}

const Table = ({ titles, children, center, fixedLayout }: Props) => (
  <table
    className={clsx(
      'table align-items-center mb-0',
      'overflow-hidden removed border-gray-300',
      fixedLayout && styles['table'],
      center && 'text-center',
    )}
  >
    {titles && (
      <thead>
        <tr>
          {titles.map((title, index) => (
            <th
              key={index}
              className={clsx('h4 px-2 font-weight-bolder', 'text-gray-600 border-gray-300')}
            >
              {title}
            </th>
          ))}
        </tr>
      </thead>
    )}
    <tbody className="border-top-0">{children}</tbody>
  </table>
);
export default Table;
