import clsx from 'clsx';
import { forwardRef, ReactNode, Ref } from 'react';

import styles from './Button.module.scss';

interface Props {
  color?: 'primary' | 'secondary' | 'info' | 'dark' | 'blue';
  outline?: boolean;
  size?: 'sm' | 'md' | 'lg';
  fullWidth?: boolean;
  type?: 'button' | 'submit';
  children?: ReactNode | undefined;
  onClick?: () => void;
  name: string;
  disabled?: boolean;
}

const Button = forwardRef(
  (
    {
      color = 'primary',
      outline = false,
      size = 'md',
      children,
      onClick,
      type = 'button',
      fullWidth,
      name,
      disabled,
    }: Props,
    ref?: Ref<HTMLButtonElement>,
  ) => (
    <button
      data-test-id={`button-${name.replace(/\s/g, '-')}`}
      type={type}
      ref={ref}
      disabled={disabled}
      className={clsx(
        'btn m-0',
        outline && !disabled && `btn-outline-${color}`,
        `btn-${size}`,
        disabled && 'bg-gray-300 disabled text-gray-700',
        fullWidth && 'w-100',
        !outline && !disabled && `text-white bg-gradient-${color}`,
        disabled && styles['disabled'],
      )}
      onClick={onClick}
    >
      {children}
    </button>
  ),
);

Button.displayName = 'Button';

export default Button;
