import type { AFError } from '@schemas/AFError';
import type { ResetPasswordValues } from '@schemas/resetPasswordSchema';
import type { SignInResponse, SignInValues } from '@schemas/signInSchema';
import axios from 'axios';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from 'react-query';

const signInUrl = '/api/sign_in';
const temporarySignInUrl = '/api/temporary/sign_in';
const MINUTE = 60_000;

interface SignInError {
  message: string;
}

export const useSignIn = (
  options: UseMutationOptions<SignInResponse, SignInError, SignInValues>,
): UseMutationResult<SignInResponse, SignInError, SignInValues> =>
  useMutation(async (credentials) => {
    try {
      const response = await axios.post(
        credentials.adminUser.rememberMe ? signInUrl : temporarySignInUrl,
        credentials,
      );
      let jwtToken = response.headers.authorization;
      if (jwtToken) {
        jwtToken = jwtToken.replace('Bearer ', '');
      }
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw { message: error.response?.data.error ?? error.message };
      } else {
        throw { message: 'An error occurred' };
      }
    }
  }, options);

const tokenUrl = '/api/token';

export const useToken = (): UseQueryResult<string, AFError> =>
  useQuery(
    'user-token',
    async () => {
      try {
        const {
          data: { token },
        } = await axios.get<{ token?: string }>(tokenUrl);

        if (token) sessionStorage.setItem('AUTH_TOKEN', token);

        return token;
      } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
          if (error.response?.data) {
            throw error.response.data as AFError;
          } else {
            throw { errorMessages: [error.message] };
          }
        } else {
          throw { errorMessages: ['An error occurred'] };
        }
      }
    },
    { staleTime: MINUTE },
  );

const resetPasswordUrl = '/api/reset_password';

interface ResetPasswordError {
  message: string;
}

export const useResetPassword = (
  options: UseMutationOptions<{}, ResetPasswordError, ResetPasswordValues>,
) =>
  useMutation(async (credentials) => {
    try {
      await axios.post(resetPasswordUrl, credentials);
      return {};
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw { message: error.response?.data.error ?? error.message };
      } else {
        throw { message: 'An error occurred' };
      }
    }
  }, options);

const signOutUrl = '/api/sign_out';

export const useSignOut = (
  options: UseMutationOptions<{}, AFError>,
): UseMutationResult<{}, AFError, void> =>
  useMutation(async () => {
    try {
      await axios.delete(signOutUrl);

      return {};
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw { errorMessages: error.response?.data.error ?? error.message };
      } else {
        throw { errorMessages: 'An error occurred' };
      }
    }
  }, options);
