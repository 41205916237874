import type { PropsWithChildren } from 'react';

interface Props {
  options: string[];
  onValueChange: (value: string) => void;
  name: string;
}

const Dropdown = ({ options, onValueChange, name }: PropsWithChildren<Props>) => (
  <select
    data-test-id={`dropdown-${name}`}
    className="border-0 bg-transparent text-white fs-5"
    onChange={(e) => {
      onValueChange(e.target?.value);
    }}
  >
    {options.map((option, index) => (
      <option
        data-test-id={`dropdown-${name}-option-${option.replace(/\s/g, '-')}`}
        className="fs-5"
        key={index}
        value={option}
      >
        {option}
      </option>
    ))}
  </select>
);
export default Dropdown;
