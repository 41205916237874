import { useToken } from '@api/session';
import { LoggedUser } from '@schemas';
import jwtDecode from 'jwt-decode';
import { useMemo } from 'react';

export interface JWTContent {
  aud: [string] | null;
  exp: number;
  iat: number;
  jti: string;
  scp: string;
  sub: string;
  email: string;
  username: string;
  type: string;
  role?: string;
}

export const useAuth = () => {
  const { data: token, isLoading } = useToken();

  const decodedToken = useMemo(() => {
    if (isLoading || !token) return undefined;

    return jwtDecode<JWTContent>(token);
  }, [token, isLoading]);

  const tokenValid = useMemo(() => {
    if (!decodedToken) return false;

    const jwtExpiration = new Date(decodedToken.exp * 1000);
    return new Date() <= jwtExpiration;
  }, [decodedToken]);

  const user: LoggedUser | undefined = useMemo(
    () =>
      decodedToken
        ? {
            username: decodedToken.username,
            email: decodedToken.email,
            role: decodedToken.role,
            type: decodedToken.type,
          }
        : undefined,
    [decodedToken],
  );

  return {
    loggedIn: tokenValid,
    token: token,
    loading: isLoading,
    user: user,
  };
};
