import { useFormContext } from 'react-hook-form';

interface Props {
  name: string;
  value?: string;
}

const HiddenInput = ({ name, value }: Props) => {
  const { register } = useFormContext();

  return (
    <input
      data-test-id={`hidden-input-${name.replace(/\s/g, '-')}`}
      value={value}
      required
      type={'hidden'}
      {...register(name)}
    />
  );
};
export default HiddenInput;
