import clsx from 'clsx';
import Image from 'next/image';
import React from 'react';
import { useEffect, useState } from 'react';
import { get, useFormContext } from 'react-hook-form';

import styles from './SelectInput.module.scss';

export interface Option {
  label: string;
  value: string | number;
}

interface Props {
  label?: string;
  name: string;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  variant?: 'main' | 'compact';
  options: Option[] | undefined;
  onValueChange?: (value: string) => void;
  onEvent?: () => void;
}

const SelectInput = ({
  name,
  label,
  placeholder,
  disabled,
  options,
  required,
  variant = 'main',
  onValueChange,
  onEvent,
}: Props) => {
  const [showOptions, setShowOptions] = useState(false);
  const [selectOptions, setSelectOptions] = useState<Option[] | undefined>(options);

  const { register, formState, getValues, setValue } = useFormContext();

  const inputValue = getValues(name);
  const [search, setSearch] = useState('');

  const id = `input-${name}`;
  const error = get(formState.errors, name);

  useEffect(() => {
    const selectedOption = options?.find(({ value }) => value === inputValue);
    setSearch(selectedOption?.label ?? '');
  }, [options, inputValue]);

  useEffect(() => {
    setSelectOptions(options);
  }, [options]);

  const selectOption = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const target = event.target as HTMLInputElement;
    const dataValue = target.getAttribute('data-value');

    onValueChange && onValueChange(dataValue as string);
    setValue(name, dataValue && parseInt(dataValue) ? parseInt(dataValue) : dataValue);
  };

  const handleInput = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;

    const filteredOptions = options?.filter((option) =>
      option.label.toLowerCase().includes(target.value.toLowerCase()),
    );

    setSearch(target.value);

    if (
      filteredOptions?.length === 1 &&
      target.value.toLowerCase() === filteredOptions[0].label.toLowerCase()
    ) {
      setValue(name, filteredOptions[0].value);
    } else if (filteredOptions && filteredOptions.length <= 1) {
      setValue(name, null);
    }

    setSelectOptions(filteredOptions);
  };

  return (
    <>
      {label && <label htmlFor={id}>{label || '\u00A0'}</label>}

      <div className="position-relative">
        <div className="position-relative">
          <input
            type="text"
            className={clsx('form-control', error && 'is-invalid')}
            disabled={disabled}
            required={required}
            placeholder={placeholder}
            onInput={(e) => handleInput(e)}
            value={search}
            onFocus={() => {
              setShowOptions(!showOptions);
              onEvent && onEvent();
            }}
            onBlur={() => {
              setShowOptions(false);
              onEvent && onEvent();
            }}
            data-test-id={`select-input-${name}`}
          />
          <div className="position-absolute top-28 end-3">
            {showOptions ? (
              <Image
                src="/assets/img/down-arrow-gray.svg"
                alt="down-arrow"
                width={10}
                height={10}
              />
            ) : (
              <Image src="/assets/img/up-arrow-gray.svg" alt="up-arrow" width={10} height={10} />
            )}
          </div>
        </div>

        <input
          type="hidden"
          {...register(name)}
          data-test-id={`select-hidden-input-${name.replace(/\s/g, '-')}`}
        />

        <div
          className={clsx('z-index-sticky position-relative', showOptions ? 'd-block' : 'd-none')}
        >
          <div className={clsx(styles['triangle-up'], 'position-absolute')} />
          <ul
            className={clsx(
              'bg-gray-100 list-group border-0 mt-2 py-2 bg-white position-absolute w-100 z-index-sticky overflow-auto',
              styles['options-container'],
            )}
          >
            {selectOptions && selectOptions.length > 0 ? (
              selectOptions.map((option) => (
                <li
                  value={option.value}
                  key={`${option.value}${name}`}
                  data-value={option.value}
                  onMouseDown={(e) => selectOption(e)}
                  className="bg-gray-100 list-group-item border-0 py-1 hover-background-primary duration-300 transition-ease"
                >
                  {option.label}
                </li>
              ))
            ) : (
              <li className="bg-gray-100 list-group-item border-0 py-1">No results found.</li>
            )}
          </ul>
        </div>
      </div>
      {error && (
        <p
          className={clsx(
            'text-danger ms-3 mt-1',
            variant !== 'compact' && 'mb-3',
            variant === 'compact' && 'mb-1',
          )}
        >
          <small>{error ? error.message : '\u00A0'}</small>
        </p>
      )}
    </>
  );
};
export default SelectInput;
