import clsx from 'clsx';
import { PropsWithChildren } from 'react';

interface Props {
  color:
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'danger'
    | 'warning'
    | 'light'
    | 'dark'
    | 'transparent';
  textColor?: string;
  inline?: boolean;
}

export const Alert = ({ color, inline, children, textColor }: PropsWithChildren<Props>) => (
  <div
    className={clsx(
      `alert-${color}`,
      inline && 'p-2 fs-6',
      textColor ? `text-${textColor}` : 'text-white',
      'alert font-weight-bold m-0',
    )}
    role="alert"
  >
    {children}
  </div>
);

export default Alert;
