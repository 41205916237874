import clsx from 'clsx';
import type { ReactNode } from 'react';

interface Props {
  header?: ReactNode;
  color?: 'white' | 'light';
  children: ReactNode;
  padded?: boolean;
  shadow?: 'none';
  variant?: boolean;
  border?: boolean;
}

const Card = ({
  header,
  children,
  padded = false,
  shadow,
  color,
  variant = false,
  border = false,
}: Props) => (
  <div
    className={clsx(
      'card',
      color && `bg-${color}`,
      padded && 'p-4',
      !padded && 'p-0',
      shadow && `shadow-${shadow}`,
      border && 'border-1',
    )}
  >
    {header !== undefined && (
      <div
        className={clsx(
          'card-header',
          !padded && 'p-0',
          !padded && variant && 'px-4 py-3 border-3 border-bottom border-primary',
        )}
      >
        {header}
      </div>
    )}
    <div className={clsx('card-body', !padded && 'p-0', variant && 'p-4')}>{children}</div>
  </div>
);

export default Card;
