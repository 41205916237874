import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';

import styles from './Pagination.module.scss';

interface Props {
  currentPage: number;
  maxPages: number;
  paramName?: string;
  position?: 'start' | 'center' | 'end';
}

const Pagination = ({ maxPages, currentPage, paramName, position = 'center' }: Props) => {
  const { pathname, query } = useRouter();

  const startNumber = useMemo(
    () => Math.max(Math.min(currentPage - 1, maxPages - 2), 1),
    [currentPage, maxPages],
  );

  const endNumber = useMemo(() => Math.min(startNumber + 2, maxPages), [startNumber, maxPages]);

  const pageNumbers = useMemo(
    () => Array.from(Array(endNumber - startNumber + 1).keys()).map((key) => key + startNumber),
    [endNumber, startNumber],
  );

  const getLink = useCallback(
    (page: number) => {
      const pageNumber = Math.max(Math.min(page, maxPages), 1);
      const queryParams = { ...query };
      queryParams[paramName ?? 'page'] = pageNumber.toString();
      return { pathname: pathname, query: queryParams };
    },
    [maxPages, pathname, query, paramName],
  );

  return (
    <nav>
      <ul className={clsx('pagination pagination-lg', `justify-content-${position}`)}>
        <li className={clsx('page-item', currentPage === 1 && 'disabled')}>
          <Link href={getLink(currentPage - 1)} passHref>
            <a data-test-id="pagination-prev" className="page-link" aria-label="Previous">
              <Image
                height={11}
                width={7}
                src="/assets/img/icons/left-chevron.svg"
                alt="Left arrow"
              />
            </a>
          </Link>
        </li>
        {startNumber > 1 && (
          <li className="page-item">
            <Link href={getLink(1)} passHref>
              <a data-test-id="pagination-1" className="page-link text-dark">
                1
              </a>
            </Link>
          </li>
        )}
        {startNumber - 1 > 1 && (
          <li className="page-item disabled">
            <div className="page-link">...</div>
          </li>
        )}
        {pageNumbers.map((number) => (
          <li key={number} className="page-item">
            <Link href={getLink(number)} passHref>
              <a
                data-test-id={`pagination-${number}`}
                className={clsx(
                  'page-link',
                  currentPage === number ? styles['pagination-active'] : 'text-dark',
                )}
              >
                {number}
              </a>
            </Link>
          </li>
        ))}
        {maxPages - endNumber > 1 && (
          <li className="page-item disabled">
            <div className="page-link">...</div>
          </li>
        )}
        {endNumber < maxPages && (
          <li className="page-item">
            <Link href={getLink(maxPages)} passHref>
              <a data-test-id={`pagination-${maxPages}`} className="page-link text-dark">
                {maxPages}
              </a>
            </Link>
          </li>
        )}
        <li className={clsx('page-item', currentPage === maxPages && 'disabled')}>
          <Link href={getLink(currentPage + 1)} passHref>
            <a data-test-id="pagination-next" className="page-link" aria-label="Next">
              <Image
                height={11}
                width={7}
                src="/assets/img/icons/left-chevron.svg"
                alt="Right arrow"
                className="flip-x"
              />
            </a>
          </Link>
        </li>
      </ul>
    </nav>
  );
};
export default Pagination;
