import clsx from 'clsx';

import styles from './LoadingIndicator.module.scss';

interface Props {
  size?: 'sm';
}

const LoadingIndicator = ({ size }: Props) => (
  <div
    className={clsx(['spinner-border m-auto d-flex', size && styles[`spinner-border-${size}`]])}
    role="status"
  >
    <span className="visually-hidden">Loading...</span>
  </div>
);

export default LoadingIndicator;
