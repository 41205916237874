import clsx from 'clsx';
import Image from 'next/image';
import type { ChangeEvent } from 'react';

import styles from './IconTextInput.module.scss';

interface Props {
  type: 'text' | 'password' | 'number' | 'email';
  autoComplete?: string;
  label?: string;
  name: string;
  required?: boolean;
  placeholder?: string;
  variant?: 'main' | 'auth' | 'compact';
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void | undefined;
  value?: string;
  onClick?: () => void;
  prepend: string;
}

const IconTextInput = ({
  name,
  type,
  required,
  placeholder,
  variant = 'main',
  disabled = false,
  autoComplete,
  onChange,
  prepend,
}: Props) => {
  const id = `input-${name}`;

  return (
    <div className={clsx('input-group mb-3', styles['input-group'])}>
      <div className={clsx('input-group-prepend', styles['input-group-prepend'])}>
        <span className="input-group-text border-0" id="basic-addon1">
          <Image src={prepend} height="18" width="18" alt="search" />
        </span>
      </div>
      <input
        onChange={onChange}
        data-test-id={`${type}-input-${name.replace(/\s/g, '-')}`}
        placeholder={placeholder}
        required={required}
        type={type}
        name={name}
        id={id}
        disabled={disabled}
        autoComplete={autoComplete}
        className={clsx(
          'form-control shadow-none border-left-0 icon-input',
          styles['form-control'],
          {
            'py-3 fs-6 mt-3': variant === 'auth',
          },
        )}
      />
    </div>
  );
};
export default IconTextInput;
