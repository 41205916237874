import clsx from 'clsx';
import Link from 'next/link';
import type { PropsWithChildren } from 'react';

interface Props {
  color?: 'primary' | 'secondary' | 'info' | 'dark' | 'blue';
  outline?: boolean;
  capitalize?: boolean;
  size?: 'sm' | 'md' | 'lg';
  fullWidth?: boolean;
  href: string;
  target?: string;
  name: string;
}

const LinkButton = ({
  color = 'primary',
  outline = false,
  size = 'md',
  children,
  href,
  fullWidth,
  capitalize,
  target,
  name,
}: PropsWithChildren<Props>) => (
  <Link href={href} passHref>
    <a
      data-test-id={`link-${name.replace(/\s/g, '-')}`}
      className={clsx(
        'btn',
        'm-0',
        `${outline ? 'btn-outline' : 'bg-gradient'}-${color}`,
        `btn-${size}`,
        fullWidth && 'w-100',
        capitalize && 'text-capitalize',
        !outline && 'text-white',
      )}
      target={target}
    >
      {children}
    </a>
  </Link>
);
export default LinkButton;
