import clsx from 'clsx';
import throttle from 'lodash.throttle';
import { useEffect, useMemo, useRef, useState } from 'react';

import styles from './ScrollGradient.module.scss';

const THROTTLE_TIME = 100;
const GRADIENT_THRESHOLD = 25;
interface Props {
  children: React.ReactNode;
}

const ScrollGradient = ({ children }: Props) => {
  const [showGradient, setShowGradient] = useState(false);
  const container = useRef<HTMLDivElement>(null);
  const child = useRef<HTMLDivElement>(null);

  const handleScroll = useMemo(
    () =>
      throttle(() => {
        if (container.current && child.current) {
          const { top } = container.current.getBoundingClientRect();
          const { top: childTop } = child.current.getBoundingClientRect();

          setShowGradient(top - childTop > GRADIENT_THRESHOLD);
        }
      }, THROTTLE_TIME),
    [container, child],
  );

  useEffect(() => {
    document.addEventListener('scroll', handleScroll, true);
    return () => document.removeEventListener('scroll', handleScroll, true);
  }, [handleScroll]);

  return (
    <div
      className={clsx(
        'position-relative h-100 d-flex',
        'overflow-auto pe-5 pe-xl-6',
        'flex-column',
        styles['scroll-container'],
      )}
    >
      <div
        className={clsx(
          'position-absolute top-0 w-100 z-index-sticky duration-200 transition-ease transition-all',
          styles['gradient-scroll'],
          { 'opacity-0': !showGradient, 'opacity-100': showGradient },
          'pe-none',
        )}
      />
      <div className="pb-5" ref={container}>
        <div ref={child} className="row m-0 p-0">
          {children}
        </div>
      </div>
    </div>
  );
};
export default ScrollGradient;
