import React from 'react';

interface Props {
  children: (React.ReactNode | string | number)[];
  onClick?: () => void;
  name?: string;
}

const TableRow = ({ children, onClick, name }: Props) => (
  <tr
    onClick={onClick}
    role={onClick !== undefined ? 'button' : undefined}
    data-test-id={name?.replace(/\s/g, '-')}
  >
    {children?.map((child, index) => (
      <td key={index}>
        <div className="align-items-center text-dark">{child}</div>
      </td>
    ))}
  </tr>
);
export default TableRow;
