import { LoadingIndicator } from '@atoms';
import clsx from 'clsx';
import Image from 'next/image';
import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { get, useFormContext } from 'react-hook-form';
import { getFileNameFromUrl, readFile, splitFileName } from 'utils/FileUtils';

import styles from './FileInput.module.scss';

interface Props {
  label?: string;
  name: string;
  src?: string;
  required?: boolean;
  onRemoveFile?: () => void;
}

const FileInput = ({ name, label, required, src = undefined, onRemoveFile }: Props) => {
  const { register, formState, setValue, watch } = useFormContext();

  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>();
  const [image, setImage] = useState<string | undefined | null>(src);

  const inputValues = watch(name);
  const id = `input-${name}`;
  const error = get(formState.errors, name);

  const removeFile = useCallback(() => {
    setValue(name, null);
    setFile(null);
    setImage(undefined);
    onRemoveFile && onRemoveFile();
  }, [name, setValue, onRemoveFile]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    readFile(
      event.target.files && event.target.files[0],
      () => setLoading(true),
      (objectUrl) => {
        setLoading(false);
        setImage(objectUrl);
      },
    );
  };

  useEffect(() => {
    if (inputValues && inputValues instanceof FileList) {
      setFile(inputValues[0]);
    } else if (inputValues) {
      const file = new File([inputValues], getFileNameFromUrl(inputValues), {
        type: 'image/png',
      });
      setFile(file);
    }

    const fileIsEmpty = inputValues !== undefined && image !== undefined;
    const imageIsEmpty =
      (inputValues === undefined || (inputValues && inputValues.length === 0)) &&
      image !== undefined;

    if (!fileIsEmpty && imageIsEmpty) {
      removeFile();
    }
  }, [removeFile, inputValues, id, image]);

  return (
    <div>
      <label htmlFor={id}>{label || '\u00A0'}</label>
      <div className={clsx(image && inputValues ? 'd-none' : 'd-flex position-relative')}>
        <label htmlFor={id} className="w-100">
          <div
            className={clsx(
              styles['form-control'],
              'form-control text-center border-1 border-primary',
            )}
            data-test-id={`file-input-button-${name.replace(/\s/g, '-')}`}
            role="button"
          >
            <h6 className="mx-3 mb-0 fs-6 text-primary fw-bolder">Upload logo</h6>
          </div>
        </label>
        <input
          required={required}
          data-test-id={`file-input-${name.replace(/\s/g, '-')}`}
          type="file"
          className="opacity-0 w-100 h-100 position-absolute pe-none"
          {...register(name, {
            onChange: handleOnChange,
          })}
          id={id}
          role="button"
        />
      </div>
      {loading && (
        <div className="m-auto">
          <LoadingIndicator />
        </div>
      )}

      {image && inputValues && !loading && (
        <div className={styles['group-logo-background']}>
          <Image
            id="group-logo"
            src={image}
            alt="Group logo"
            width={135}
            height={135}
            layout="responsive"
          />
        </div>
      )}

      {file && inputValues && (
        <div className={clsx(styles['file-list'], 'mt-2 text-dark pe-5 d-flex align-items-center')}>
          <div className="text-truncate">{splitFileName(file.name)[0]}</div>
          <span>.{splitFileName(file.name)[1]}</span>
          <span
            className="ms-2"
            role="button"
            onClick={() => removeFile()}
            data-test-id={`remove-file-button-${name.replace(/\s/g, '-')}`}
          >
            <Image
              src="/assets/img/icons/remove_file_icon.svg"
              alt="Remove file icon"
              width={24}
              height={24}
            />
          </span>
        </div>
      )}

      {error && (
        <p className="text-danger ms-1">
          <small>{error ? error.message : '\u00A0'}</small>
        </p>
      )}
    </div>
  );
};

export default FileInput;
