import { userAbilities } from '@constants/userAbilities';
import { useCallback, useMemo } from 'react';

import { useAuth } from './useAuth';

export const useAbility = (ability: string | string[]) => {
  const { user } = useAuth();

  const checkAbility = useCallback(
    (ability: string) => {
      const abilityFunction = userAbilities[ability];

      if (!abilityFunction) {
        return true;
      }

      return abilityFunction(user);
    },
    [user],
  );

  const result: boolean | { [key: string]: boolean } = useMemo(() => {
    if (!Array.isArray(ability)) {
      return checkAbility(ability);
    }

    return ability.reduce(
      (aggregate, current) => ({
        ...aggregate,
        [current]: checkAbility(current),
      }),
      {},
    );
  }, [ability, checkAbility]);

  return result;
};
