import clsx from 'clsx';

import styles from './Spinner.module.scss';

interface Props {
  name: string;
}

const Spinner = ({ name }: Props) => (
  <div data-test-id={`spinner-${name}`} className={clsx(styles['spinner'], 'position-relative')}>
    <div className="position-absolute top-50 start-50 translate-middle">
      <div className={clsx(styles['spinner-background'], '')} />
      <div className={styles['rounded-cap']} />
    </div>
  </div>
);
export default Spinner;
