import Image from 'next/image';
import { ReactElement } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

interface Props {
  placement: 'top' | 'right' | 'left' | 'bottom';
  children: ReactElement;
  text: string;
  icon?: string;
  name: string;
  show?: boolean; // If show isn't set it will automatically show on hover
}

const Tooltip = ({ placement, text, icon, children, name, show }: Props) => (
  <OverlayTrigger
    show={show}
    placement={placement}
    overlay={
      <Popover data-test-id={`tooltip-${name.replace(/\s/g, '-')}`}>
        <Popover.Body>
          <div className="d-flex align-items-center">
            {icon && <Image src={icon} width={30} alt="" height={30} />}
            <span className="mx-1 flex-grow-1">{text}</span>
          </div>
        </Popover.Body>
      </Popover>
    }
  >
    {(props) => <div {...props}>{children}</div>}
  </OverlayTrigger>
);
export default Tooltip;
