import clsx from 'clsx';
import type { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';

import styles from './ColorInput.module.scss';

interface Props {
  format: 'Hex' | 'RGB';
  name: string;
  value: string | null;
}

const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (result) {
    return {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    };
  }

  return { r: 0, g: 0, b: 0 };
};

const ColorInput = ({ name, format }: Props) => {
  const { register, watch, setValue } = useFormContext();
  const id = `input-${name}`;

  const colorValue: string = watch(name);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const parsedValue = e.target?.value.replace(/[^a-fA-F\d]/g, '');
    setValue(name, `#${parsedValue}`);
  };

  const handleRgbChange = (
    red = false,
    green = false,
    blue = false,
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    if (red) {
      const valueR: number = e.target.value ? parseInt(e.target.value) : 0;
      rgbToHex(valueR, hexToRgb(colorValue)['g'], hexToRgb(colorValue)['b']);
    } else if (green) {
      const valueG: number = e.target.value ? parseInt(e.target.value) : 0;
      rgbToHex(hexToRgb(colorValue)['r'], valueG, hexToRgb(colorValue)['b']);
    } else if (blue) {
      const valueB: number = e.target.value ? parseInt(e.target.value) : 0;
      rgbToHex(hexToRgb(colorValue)['r'], hexToRgb(colorValue)['g'], valueB);
    }
  };

  const rgbToHex = (red: number, green: number, blue: number) => {
    const hexColor = '#' + ((1 << 24) + (red << 16) + (green << 8) + blue).toString(16).slice(1);
    setValue(name, hexColor);
  };

  return (
    <div className="d-flex align-items-center justify-content-around">
      {format === 'Hex' && (
        <input
          data-test-id={`color-input-hex-${name.replace(/\s/g, '-')}`}
          type="text"
          maxLength={7}
          value={colorValue}
          onChange={handleChange}
          className={clsx(
            'text-center w-50 border-0',
            'mx-3 rounded-3 bg-white',
            'text-dark-purple fw-bolder text-uppercase fs-6',
          )}
        />
      )}
      {format == 'RGB' && (
        <div className="d-flex justify-content-around w-75">
          <input
            data-test-id={`color-input-r-${name.replace(/\s/g, '-')}`}
            type="text"
            value={hexToRgb(colorValue)['r']}
            className="text-center w-35 border-0 rounded-3 bg-white text-dark-purple fw-bolder text-uppercase mx-0 fs-6"
            onChange={(event) => handleRgbChange(true, false, false, event)}
          />
          <input
            data-test-id={`color-input-g-${name.replace(/\s/g, '-')}`}
            type="text"
            value={hexToRgb(colorValue)['g']}
            className="text-center w-35 border-0 rounded-3 bg-white text-dark-purple fw-bolder text-uppercase mx-1 fs-6"
            onChange={(event) => handleRgbChange(false, true, false, event)}
          />
          <input
            data-test-id={`color-input-b-${name.replace(/\s/g, '-')}`}
            type="text"
            value={hexToRgb(colorValue)['b']}
            className="text-center w-35 border-0 rounded-3 bg-white text-dark-purple fw-bolder text-uppercase mx-0 fs-6"
            onChange={(event) => handleRgbChange(false, false, true, event)}
          />
        </div>
      )}
      <input
        data-test-id={`color-input-color-${name.replace(/\s/g, '-')}`}
        type="color"
        {...register(name)}
        id={id}
        className={clsx(
          'form-control form-control-color border-0 bg-transparent p-0 d-flex alig-self-center',
          styles['color-input'],
        )}
      />
    </div>
  );
};

export default ColorInput;
