import clsx from 'clsx';
import Image from 'next/image';

import styles from './FilledIcon.module.scss';

interface Props {
  source: string;
  alt: string;
}

const FilledIcon = ({ source, alt }: Props) => (
  <div className={clsx(styles['filled-icon'], 'bg-dark', 'position-relative')}>
    <Image src={source} alt={alt} layout="fill" className="p-2" />
  </div>
);
export default FilledIcon;
