import clsx from 'clsx';

interface Props {
  name: string;
  id: number;
  selected: boolean;
  onClick: () => void;
}

const AssetCategoryTag = ({ name, id, selected, onClick }: Props) => (
  <div
    data-test-id={`category-tag-${id}`}
    role="button"
    className={clsx(selected && 'bg-primary', 'd-inline-flex rounded-pill px-3')}
    onClick={onClick}
    data-category={id}
  >
    <span className="fs-4 fw-bolder text-white text-capitalize">{name}</span>
  </div>
);
export default AssetCategoryTag;
